<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'system:stage:list:button:delete'"
        >删除</a-button
      >
      <a-button
        type="primary"
        class="mr-3"
        @click="pSync"
        v-if="selectedRowKeys.length"
        :disabled="
          selectedRowKeys.length >= 2 ||
            !(selectionRows.length && selectionRows[0].departments && selectionRows[0].departments.length)
        "
        icon="cloud"
        v-has="'system:rule:list:button:add'"
        >同步</a-button
      >
      <a-button type="primary" @click="openAddForm" v-has="'system:stage:list:button:add'">新建</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px" v-if="false">
        <i class="anticon anticon-info-circle ant-alert-icon"></i>已选择<a class="mx-1 text-bold">{{
          selectedRowKeys.length
        }}</a
        >项&nbsp;&nbsp;
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        style="border-top:1px solid rgb(232,232,232)"
        v-has="'system:stage:list:page:view'"
        ref="table"
        size="middle"
        rowKey="stageId"
        :columns="columns"
        :dataSource="dataSource"
        :customRow="clickRow"
        :pagination="ipagination"
        :scroll="{ x: '1200px', y: 'calc(100vh - 341px)' }"
        :loading="loading"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 62 }"
        @change="handleTableChange"
      >
        <template slot="avatarslot" slot-scope="text, record">
          <div class="anty-img-wrap">
            <a-avatar shape="square" :src="getAvatarView(record.avatar)" icon="user" />
          </div>
        </template>

        <span slot="stageType" slot-scope="text, record">
          <label>{{ record.stageType == 1 ? '否' : '是' }}</label>
        </span>

        <span slot="departmentNames" slot-scope="text, record">
          <a-tag v-for="one in record.departments" :key="one.departId">
            {{ one.departName }}
          </a-tag>
        </span>
        <span slot="rejectList" slot-scope="text, record">
          <a-tag v-for="one in record.rejectList" :key="one.stageId">
            {{ one.stageName }}
          </a-tag>
        </span>
        <span slot="kpiList" slot-scope="text, record">
          <a-tag v-for="one in record.kpiList" :key="one.stageId">
            {{ one.stageName }}
          </a-tag>
        </span>
      </a-table>
    </div>
    <!-- table区域-end -->
    <stage-modal :open="drawerShow" :cur-row="curRow" :isForm="isNewForm" @closed="drawerClosed"></stage-modal>
    <sync-modal
      ref="syncModal"
      url="/stage/sync"
      @loadData="loadData"
      :selectedRowKeys="selectedRowKeys"
      :selectionRows="selectionRows"
    />
  </body-layout>
</template>

<script>
import StageModal from './modules/stage/stage-modal'
import syncModal from '@/components/sync-modal'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import JInput from '@/components/jeecg/JInput'
import { getAction } from '@/api/manage'
export default {
  name: 'StageList',
  mixins: [JeecgListMixin],
  components: {
    StageModal,
    syncModal,
    JInput
  },
  data() {
    return {
      description: '阶段管理界面',
      queryParam: {},
      recycleBinVisible: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 60,
          sorter: false
        },
        {
          title: '阶段名称',
          dataIndex: 'stageName',
          width: 120,
          sorter: false
        },
        {
          title: '人员职位',
          width: 100,
          dataIndex: 'positionName'
        },
        /*         {
          title: '是否可逆',
          width: 100,
          scopedSlots: { customRender: 'stageType' },
          dataIndex: 'stageType',
        }, */
        {
          title: '可驳回阶段',
          scopedSlots: { customRender: 'rejectList' },
          dataIndex: 'rejectList',
          width: 180
        },
        {
          title: '可考核阶段',
          scopedSlots: { customRender: 'kpiList' },
          dataIndex: 'kpiList',
          width: 180
        },
        {
          title: '适用部门',
          scopedSlots: { customRender: 'departmentNames' },
          dataIndex: 'departments',
          width: 600,
          filters: [],
          onFilter: (value, record) => {
            return record.departments.findIndex(item => item.departId == value) > -1
          },
          filterIcon: (filtered) => {
            if(filtered){
              return <span class="iconfont icon-shaixuan icon" style={{color:this.color}}></span>
            }else{
              return <span class="iconfont icon-shaixuan icon"></span>
            }
          }
        }
      ],
      color:'',
      url: {
        syncUser: '/act/process/extActProcess/doSyncUser',
        list: '/stage/list',
        delete: '/stage/delete',
        deleteBatch: '/stage/delete_batch'
      }
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  mounted(){
    this.color = this.$store.state.app.color
  },
  methods: {
    handleMenuClick(e) {
      if (e.key == 1) {
        this.batchDel()
      } else if (e.key == 2) {
        this.batchFrozen(2)
      } else if (e.key == 3) {
        this.batchFrozen(1)
      }
    },
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      params.current = params.pageNo
      params.size = params.pageSize
      getAction(this.url.list, params).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          res.data = res.data || res.data
          //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          this.dataSource = res.data.records || res.data
          if (res.data.total) {
            this.ipagination.total = res.data.total - 0
          } else {
            this.ipagination.total = 0
          }
          //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

          // 字体预览需要往head里插入css样式
          if (this.isInserstCss) {
            batchStyleInsertHeader(this.dataSource)
          }
          let departments = []
          this.dataSource.forEach(item => {
            item.departments?.forEach(d => {
              if (departments.findIndex(i => i.departName == d.departName) == -1) {
                departments.push(d)
              }
            })
          })
          this.columns[this.columns.length - 1].filters = departments.map(item => {
            return { text: item.departName, value: item.departId }
          })
          this.$emit('loadData', this.dataSource)
        }
        if (res.code === 510) {
          this.$message.warning(res.message)
        }
        this.loading = false
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@assets/less/common.less';
/deep/ .border-bottom {
  border: none;
}
/deep/ .ant-table-filter-dropdown-btns {
  display: flex !important;
  justify-content: space-around !important;
}
/deep/ .ant-table-filter-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 20px;
}
/deep/ .ant-dropdown-menu-item{
  display: flex !important;
}
</style>
