var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "system:stage:list:button:delete",
                      expression: "'system:stage:list:button:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "system:rule:list:button:add",
                      expression: "'system:rule:list:button:add'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: {
                    type: "primary",
                    disabled:
                      _vm.selectedRowKeys.length >= 2 ||
                      !(
                        _vm.selectionRows.length &&
                        _vm.selectionRows[0].departments &&
                        _vm.selectionRows[0].departments.length
                      ),
                    icon: "cloud",
                  },
                  on: { click: _vm.pSync },
                },
                [_vm._v("同步")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "system:stage:list:button:add",
                  expression: "'system:stage:list:button:add'",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.openAddForm },
            },
            [_vm._v("新建")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          false
            ? _c(
                "div",
                {
                  staticClass: "ant-alert ant-alert-info",
                  staticStyle: { "margin-bottom": "16px" },
                },
                [
                  _c("i", {
                    staticClass: "anticon anticon-info-circle ant-alert-icon",
                  }),
                  _vm._v("已选择"),
                  _c("a", { staticClass: "mx-1 text-bold" }, [
                    _vm._v(_vm._s(_vm.selectedRowKeys.length)),
                  ]),
                  _vm._v("项  \n      "),
                  _c(
                    "a",
                    {
                      staticStyle: { "margin-left": "24px" },
                      on: { click: _vm.onClearSelected },
                    },
                    [_vm._v("清空")]
                  ),
                ]
              )
            : _vm._e(),
          _c("a-table", {
            directives: [
              {
                name: "has",
                rawName: "v-has",
                value: "system:stage:list:page:view",
                expression: "'system:stage:list:page:view'",
              },
            ],
            ref: "table",
            staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
            attrs: {
              size: "middle",
              rowKey: "stageId",
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              customRow: _vm.clickRow,
              pagination: _vm.ipagination,
              scroll: { x: "1200px", y: "calc(100vh - 341px)" },
              loading: _vm.loading,
              rowSelection: {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
                columnWidth: 62,
              },
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "avatarslot",
                fn: function (text, record) {
                  return [
                    _c(
                      "div",
                      { staticClass: "anty-img-wrap" },
                      [
                        _c("a-avatar", {
                          attrs: {
                            shape: "square",
                            src: _vm.getAvatarView(record.avatar),
                            icon: "user",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "stageType",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _c("label", [
                      _vm._v(_vm._s(record.stageType == 1 ? "否" : "是")),
                    ]),
                  ])
                },
              },
              {
                key: "departmentNames",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(record.departments, function (one) {
                      return _c("a-tag", { key: one.departId }, [
                        _vm._v(
                          "\n          " + _vm._s(one.departName) + "\n        "
                        ),
                      ])
                    }),
                    1
                  )
                },
              },
              {
                key: "rejectList",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(record.rejectList, function (one) {
                      return _c("a-tag", { key: one.stageId }, [
                        _vm._v(
                          "\n          " + _vm._s(one.stageName) + "\n        "
                        ),
                      ])
                    }),
                    1
                  )
                },
              },
              {
                key: "kpiList",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(record.kpiList, function (one) {
                      return _c("a-tag", { key: one.stageId }, [
                        _vm._v(
                          "\n          " + _vm._s(one.stageName) + "\n        "
                        ),
                      ])
                    }),
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("stage-modal", {
        attrs: {
          open: _vm.drawerShow,
          "cur-row": _vm.curRow,
          isForm: _vm.isNewForm,
        },
        on: { closed: _vm.drawerClosed },
      }),
      _c("sync-modal", {
        ref: "syncModal",
        attrs: {
          url: "/stage/sync",
          selectedRowKeys: _vm.selectedRowKeys,
          selectionRows: _vm.selectionRows,
        },
        on: { loadData: _vm.loadData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }